import React from 'react';
import { Card, Typography, Box, Button } from '@mui/material';
import { AttachMoney, TrendingUp, TrendingDown } from '@mui/icons-material';
import axios from 'axios';
import config from '../config';

function SummarySection({ totalDeposits, totalExpenses, profitOrLoss, isProfit, startDate }) {
    const handleSaveSummary = () => {
        // Format the month based on the start date
        const month = startDate ? startDate.toLocaleString('default', { month: 'long', year: 'numeric' }) : 'Unknown';

        // Create the payload with the summary data
        const summaryData = {
            totalDeposits: parseFloat(totalDeposits || 0), // Ensure numeric value
            totalExpenses: parseFloat(totalExpenses || 0), // Ensure numeric value
            profitOrLoss: parseFloat(profitOrLoss || 0), // Ensure numeric value
            isProfit,
            month,
        };

        // Make the API call to save the summary data
        axios.post(`${config.baseUrl}/api/csv/saveSummary`, summaryData)
            .then(response => {
                alert('Summary data saved successfully!');
            })
            .catch(error => {
                console.error('Error saving summary data:', error);
                alert('Failed to save the summary data. Please try again.');
            });
    };

    // Ensure numeric values for display
    const formattedTotalDeposits = parseFloat(totalDeposits || 0).toFixed(2);
    const formattedTotalExpenses = parseFloat(totalExpenses || 0).toFixed(2);
    const formattedProfitOrLoss = parseFloat(profitOrLoss || 0).toFixed(2);

    return (
        <Card style={{ padding: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
            <Typography variant="h5" style={{ marginBottom: '10px' }}>Summary</Typography>
            <Box display="flex" alignItems="center">
                <AttachMoney style={{ marginRight: '5px' }} />
                <Typography variant="subtitle1">Total Deposits: ${formattedTotalDeposits}</Typography>
            </Box>
            <Box display="flex" alignItems="center" style={{ marginTop: '10px' }}>
                <AttachMoney style={{ marginRight: '5px' }} />
                <Typography variant="subtitle1">Total Expenses: ${formattedTotalExpenses}</Typography>
            </Box>
            <Box display="flex" alignItems="center" style={{ marginTop: '10px' }}>
                {isProfit ? <TrendingUp style={{ color: 'green', marginRight: '5px' }} /> : <TrendingDown style={{ color: 'red', marginRight: '5px' }} />}
                <Typography style={{ color: isProfit ? 'green' : 'red' }}>
                    {isProfit ? 'Net Profit:' : 'Net Loss:'} ${formattedProfitOrLoss}
                </Typography>
            </Box>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSaveSummary}
                style={{ marginTop: '20px' }}
            >
                Save Summary
            </Button>
        </Card>
    );
}

export default SummarySection;
