// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBjjGRirr3eAnuihO418dtKVI-FxTJCttI",
    authDomain: "ohanaauthentication.firebaseapp.com",
    projectId: "ohanaauthentication",
    storageBucket: "ohanaauthentication.firebasestorage.app",
    messagingSenderId: "22372468653",
    appId: "1:22372468653:web:93fdfca17fce66bc264324"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
