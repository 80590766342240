// src/components/Login.js
import React from 'react';
import { auth, provider } from './firebaseConfig';
import { signInWithPopup } from 'firebase/auth';
import { Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function Login() {
    const navigate = useNavigate(); // Create the navigate function

    const handleGoogleLogin = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log('User signed in:', result.user);
                navigate('/'); // Redirect to home after successful login
            })
            .catch((error) => {
                console.error('Error during sign-in:', error);
                alert('Failed to sign in. Please try again.');
            });
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
            <Typography variant="h4" marginBottom="20px">Login to Financial Dashboard</Typography>
            <Button variant="contained" color="primary" onClick={handleGoogleLogin}>
                Sign in with Google
            </Button>
        </Box>
    );
}

export default Login;
