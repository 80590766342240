import React from 'react';
import { Box, Button, Card, TextField } from '@mui/material'; // Added TextField import
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function DatePickers({ startDate, setStartDate, endDate, setEndDate, onSubmit }) {
    return (
        <Card style={{ padding: '20px', borderRadius: '8px', marginBottom: '20px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={setStartDate}
                    renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={setEndDate}
                    renderInput={(params) => <TextField {...params} style={{ marginLeft: '15px' }} />}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    style={{ marginLeft: '20px', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)' }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = '#2d72d9'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = '#3f51b5'}
                >
                    Get Transactions
                </Button>
            </Box>
        </Card>
    );
}

export default DatePickers;
