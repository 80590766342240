import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    ReferenceLine, Dot, Brush
} from 'recharts';
import { Box, Typography } from '@mui/material';

function CustomDot({ cx, cy, value }) {
    return (
        <circle cx={cx} cy={cy} r={5} fill={value >= 0 ? 'green' : 'red'} stroke="white" strokeWidth={2} />
    );
}

function ProfitLossChart() {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        axios.get(`${config.baseUrl}/api/csv/summary`)
            .then(response => {
                let data = response.data;

                // Sort and process data
                data.sort((a, b) => new Date(a.month) - new Date(b.month));

                setChartData(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const average = chartData.length > 0
        ? chartData.reduce((sum, item) => sum + item.profitOrLoss, 0) / chartData.length
        : 0;

    return (
        <Box>
            <Typography variant="h4" style={{ marginBottom: '20px' }}>Profit and Loss by Month</Typography>
            {chartData.length > 0 ? (
                <ResponsiveContainer width="100%" height={500}>
                    <LineChart data={chartData}>
                        {/* Gradient definition */}
                        <defs>
                            <linearGradient id="colorProfitLoss" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                            </linearGradient>
                        </defs>

                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip
                            formatter={(value, name, props) => [`$${value.toFixed(2)}`, 'Profit/Loss']}
                            labelFormatter={(label) => `Month: ${label}`}
                        />
                        <Legend verticalAlign="top" height={36} />
                        <Line
                            type="monotone"
                            dataKey="profitOrLoss"
                            stroke="#8884d8"
                            strokeWidth={3}
                            fill="url(#colorProfitLoss)"
                            dot={<CustomDot />}
                            activeDot={{ r: 8 }}
                            animationDuration={1500}
                            animationEasing="ease-in-out"
                        />
                        <ReferenceLine y={average} label={`Avg: $${average.toFixed(2)}`} stroke="red" strokeDasharray="3 3" />

                        {/* Zoom and pan */}
                        <Brush dataKey="month" height={30} stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
            ) : (
                <Typography>Loading data...</Typography>
            )}
        </Box>
    );
}

export default ProfitLossChart;
