// TransactionGroup.js
import React from 'react';
import { Button } from '@mui/material';
import {
    Accordion, AccordionSummary, AccordionDetails,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Typography, Checkbox, Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function TransactionGroup({
    transactions, hiddenTransactions, onHideAllChange, onCheckboxChange, vendor, expanded, onAccordionChange
}) {
    // Calculate total amount based on visible transactions
    const visibleTransactions = transactions.filter(transaction => !hiddenTransactions.has(transaction.id));
    const totalAmount = visibleTransactions.reduce((sum, transaction) => sum + Math.abs(transaction.amount), 0).toFixed(2);

    // Determine if all transactions are hidden for the "Hide All" checkbox
    const allHidden = transactions.every(transaction => hiddenTransactions.has(transaction.id));

    // Prevent the accordion from expanding when the "Hide All" checkbox is clicked
    const handleHideAllClick = (e) => {
        e.stopPropagation();
        onHideAllChange(transactions, e.target.checked);
    };

    return (
        <Accordion expanded={expanded} onChange={onAccordionChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center" width="100%">
                    {/* "Hide All" checkbox on the left */}
                    <Checkbox
                        onChange={handleHideAllClick}
                        checked={allHidden}
                        style={{ marginRight: '10px' }}
                        onClick={(e) => e.stopPropagation()} // Prevent event propagation to Accordion
                    />
                    <Typography variant="subtitle1" flexGrow={1}>
                        {vendor} (Total: ${totalAmount})
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Hide</strong></TableCell>
                                <TableCell><strong>ID</strong></TableCell>
                                <TableCell><strong>Description</strong></TableCell>
                                <TableCell><strong>Amount</strong></TableCell>
                                <TableCell><strong>Date</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.map(transaction => (
                                <TableRow key={transaction.id}>
                                    <TableCell>
                                        <Checkbox
                                            checked={hiddenTransactions.has(transaction.id)}
                                            onChange={() => onCheckboxChange(transaction.id)}
                                        />
                                    </TableCell>
                                    <TableCell>{transaction.id}</TableCell>
                                    <TableCell>{transaction.description}</TableCell>
                                    <TableCell>{Math.abs(transaction.amount).toFixed(2)}</TableCell>
                                    <TableCell>{transaction.date}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    );
}

export default TransactionGroup;